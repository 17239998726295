/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Michelské pekárny Premium s.r.o. - Historie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim3 --center pt--80" style={{"backgroundColor":"rgba(207,102,102,1)","paddingBottom":6}} name={"[[UNIsecname1]]"} anim={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-dominant);\">Podporujeme</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l13"} name={"7hps86oiv22"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Podpořili jsme turnaj v kolové - za sportovní výkon sladkou odměnu!</span>"}>
              </Title>

              <Text className="text-box text-box--justify" content={"TJ Pankrác Praha jsme opět poskytli naše výrobky - Metro dezerty, které využili pořadatelé jako odměny pro závodníky v rámci Mezinárodního turnaje v kolové Zlaté Kolo. <br>Na startu bylo 8 týmů z 5 zemí. Krom českých dvojic startovaly také týmy z Německa, Rakouska, Francie a Švýcarska.<br>Hlavními favority turnaje byly dva týmy, které tyto předpoklady potvrdily. Tým Praha/Svitávka (Jan Kripner/Jan Hrdlička) a německý tým Gärtringenu. Tyto dva týmy se utkaly také v letošním zápase o bronzové medaile na mistrovství Evropy do 23 let. V napínavém utkání nakonec zvítězil český tým a vybojoval tak skvělý medailový úspěch v této věkové kategorii.<br>Gratulujeme!<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/332d752ad03d45a9a28acef8eb38be67_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/38877/332d752ad03d45a9a28acef8eb38be67_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38877/332d752ad03d45a9a28acef8eb38be67_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38877/332d752ad03d45a9a28acef8eb38be67_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"72av0oxhvb3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Konference</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" content={"Společnost Michelské pekárny Premium opět přispěla věcným sponzorským darem neziskové organizaci Model United Nations Prague o. s., při organizaci mezinároní konference PragueMUN 2016. Konference se letos účastnilo téměř 200 studentů z celého světa, z oblastí diplomacie, ekonomie a veřejné politiky. Model Conference Prague OSN je první mezinárodní modelovou konferenci OSN v České republice, založené na Vysoké škole ekonomické v Praze.\nA jak jsme se dozvěděli od organizátorů, naše výrobky Metro dezert a Kukuřičné křupky se opět setkaly s kladným ohlasem.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mzz1k2fw6cf"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Sponzorský dar seniorům na výletě spolku Život 90</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":334}} content={"Dne 13. prosince 2014 organizace Život 90 uspořádala pro seniory vánoční výlet po vyzdobené Praze - kostely, betlémy, muzea a trhy.\nAkce se zúčastnilo 515 seniorů, o které se staralo 50 dobrovolníků a na výlet vyrazili 23 autobusy. Nejstarším účastnicím bylo 94 let."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":334}} content={"Rádi jsme přispěli drobným dárkem zúčastněným osamělým a hendikepovaným seniorům a pomohli tak zpříjemnit jejich předvánoční období."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"8r9qf0moa3o"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Den v Podskalí, Centrum duševního zdraví</span>"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":650}} content={"Michelské pekárny Premium přispěly sponzorským darem na akci pořádanou společností FOKUS Praha - Den v Podskalí, Centrum duševního zdraví.&nbsp; Dne 1. října 2014 proběhl den otevřených dveří v centru v Podskalí a premiérové promítání dokumentárního filmu Paralelní životy. Akce se uskutečnila v rámci Týdnů pro duševní zdraví 2014.\nK dobré pohodě hrála skupina Lahoda. Odpoledne patřilo debatám ve třech diskuzních blocích. Diskuzí se účastnili jak klienti, kterých se reforma psychiatrické péče dotkne osobně, tak sociální pracovníci, odborní profesionálové a politici, kteří reformu mají možnost ovlivnit a utvářet. Zazněly obavy i očekávání. Ruku v ruce s reformou a zakládáním Center duševního zdraví, je potřeba řešit sociální bydlení, které totiž často suplují psychiatrické nemocnice, mnohdy v nedůstojných podmínkách.\nVe Starém purkrabství i v Centru Podskalí proběhla sbírková akce, která bude využita na činnost Fokusu Praha."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--justify">
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Metro dezert pro legionáře</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Michelské pekárny Premium s r.o. věnovaly na Vánoční setkání Československé obce legionářské sponzorský dar do dárkového balíčku legionářům. Dle informací organizátorů byli účastníci velmi mile potěšeni Metro dezertem, dokonce byl zaslán i balíček legoináři do Ameriky, který se ze zdravotních důvodů nemohl akce zúčastnit, následně telefonoval a osobně za dárek poděkoval."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"056xnfipd8i7"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Podporujeme UNICEF</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"Michelské pekárny se připojily k projektu Českého výboru pro UNICEF - charitativní akce \"Pečení pro děti\" a poskytly koláče pro charitativní prodej na nám. Republiky v Praze dne 31. 8. 2012. V prodejním stánku jsou výrobky nabízeny zákazníkům za dobrovolný příspěvek. Výtěžek prodeje podporuje vzdělávací projekt \"Školy pro Afriku\"."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"abflj7yc2yq"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Dámský pochod noční Prahou</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" style={{"maxWidth":334}} content={"Výjimečné dobrodružství můžete zažít o půlnoci 15. 6 .2012, kdy začína 20 km dlouhý dámský Midnight Walk 2012 na podporu Domova Sue-Ryder v Michli.\nMichelské pekárny byly přirozeným partnerem michelského domova seniorů Sue Ryder, a tak nemohly chybět na výjimečné akci právě na podporu tohoto Domova."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--10" style={{"maxWidth":366}} content={"Údaje o společnosti <br>Michelské pekárny a.s.\n<br>Michelské pekárny Premium s.r.o.\n<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--11" style={{"maxWidth":366}} content={"<span style=\"font-weight: bold;\">Michelské pekárny Premium s.r.o.\n</span><br>sídlo a kontaktní adresa společnosti:\n<br>U krčského nádraží 229/17, Krč, 140 00 Praha 4\n<br>zapsaná v obchodním rejstříku Městského soudu v Praze,\n<br>oddíl C, vložka 198709\n<br>IČ: 242 63 362 DIČ: CZ24263362\n<br>Výše základního kapitálu 200 000,- Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--11" style={{"maxWidth":473}} content={"<span style=\"font-weight: bold;\">Michelské pekárny a.s.\n</span><br>sídlo a kontaktní adresa společnosti: <br>U krčského nádraží 229/17, Krč, 140 00 Praha 4<br>zapsaná v obchodním rejstříku Městského soudu v Praze, <br>oddíl B, vložka 648\n<br>IČ: 161 92 583 DIČ: CZ16192583\n<br>Výše základního kapitálu 3 445 498,- Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}